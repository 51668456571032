


const Footersection = () => {
    return <>
    <div class="footer-section mt-4 pt-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <img class="logo-card" src="https://d1tgh8fmlzexmh.cloudfront.net/ccbp-responsive-website/food-munch-logo-light.png"/>
          <h1 class="footer-heading mt-3"> orderfood@foodmuch.com</h1>
          <p class="footer-paragraph "> 123 Ayur Vigyan Nagar, New Delhi, India.</p>
        </div>
      </div>
    </div>
  </div>
    </>
}

export default Footersection
